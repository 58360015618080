<template>
  <div class="mx-auto contentsWidth mt-4">
    <div class="">
      <p class="title mb-0">{{year}}年度 {{name}} - 申請</p>
      <hr class="title">
    </div>

    <div class="contentsWidth mx-auto err-wrap">
      <p class="mb-0"
        v-if="!onGoing && !isAfterResult">結果発表が完了しているため、ステータスの変更はできません。</p>
    </div>

    <div
      class="mb-3 mx-auto contentsWidth">
      <div>
        <v-app-control
          :base="base"
          :status="status"
          :mailInfo="mailInfo"
          :onGoing="onGoing"
          :userName="base.ans_name"
          :isAuthAlternate="isAuthAlternate"
          :isChangeHistory="status === 10"/>
      </div>
      <div
        v-for="(page, index) in pages" :key="index">
        <v-app-form
          :writable="true"
          :pageIndex="index"
          :assets="assets"
          :downloadOpenFile="downloadOpenFile"
          :downloadFormFile="downloadFormFile"
          :downloadApploadedFile="downloadApploadedFile"/>
      </div>
      <div>
        <v-app-control
          :base="base"
          :status="status"
          :mailInfo="mailInfo"
          :onGoing="onGoing"
          :userName="base.ans_name"
          :isUnderShow="true"
          :isAuthAlternate="isAuthAlternate"/>
      </div>
    </div>

    <div class="bottonArea mt-4 mb-3 flex flexCenter">
      <p
        v-show="hasEmpty"
        class="warning">
        未入力の必須項目があります。すべての必須項目に入力後、登録を行ってください。
      </p>
    </div>

    <div class="bottonArea mt-3 mb-3 flex flexCenter">
      <b-button
        v-show="hasPrevApp"
        @click="prevApp"
        class="btn btn-primary btn-lg mr-4 bold">
        前の申請
      </b-button>
      <b-button
        class="btn btn-lg mr-4 bold"
        to="/cms/applications/list">
        一覧に戻る
      </b-button>
      <b-button
        @click="save"
        variant="info"
        class="btn btn-primary btn-lg mr-4 bold">
        登録
      </b-button>
      <b-button
        v-show="hasNextApp"
        @click="nextApp"
        class="btn btn-primary btn-lg mr-4 bold">
        次の申請
      </b-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import download from '@/modules/download';
import AppForm from '@/components/gas/parts/Form.vue';
import AppControl from '@/components/cms/applications/Control.vue';
import CONST_STATUS from '@/constants/appStatus';

export default {
  name: 'cmsApplicationEdit',
  components: {
    'v-app-form': AppForm,
    'v-app-control': AppControl,
  },
  data() {
    return {
      base: {},
      applicationId: null,
      applicationTypeId: null,
      year: null,
      name: '',
      status: null,
      hasUserErr: false,
      hasPageErr: [],
      assets: {},
      mailInfo: {},
      close_datetime: null,
      authAlternate: [],
    };
  },
  computed: {
    isAuthAlternate() {
      return this.authAlternate.indexOf(this.name) !== -1;
    },
    pages() {
      return this.$store.state.gasApplicationForm.pages;
    },
    filterdIdList() {
      return this.$store.state.cmsApplicationSearch.filterdIdList;
    },
    filterdIdListIndex() {
      return this.filterdIdList.indexOf(Number(this.applicationId));
    },
    hasNextApp() {
      if (this.filterdIdList.length === 0) {
        return false;
      }
      const newIndex = this.filterdIdListIndex + 1;
      return newIndex < this.filterdIdList.length;
    },
    hasPrevApp() {
      if (this.filterdIdList.length === 0) {
        return false;
      }
      const newIndex = this.filterdIdListIndex - 1;
      return newIndex >= 0;
    },
    hasEmpty() {
      // 入力フォーム
      const { inputs } = this.$store.state.gasApplicationInput;
      const formOptionData = this.$store.state.gasApplicationForm.optionDataList;
      const findFormResult = inputs.find((input) => {
        if (formOptionData[input.option_id] && !formOptionData[input.option_id].is_required) {
          return false;
        }
        const noSelect = input.field_answer_id === null;
        const notext = input.field_answer_text === '';
        const noFile = input.uploadedFileKey === '';
        return noSelect && notext && noFile;
      });
      return typeof findFormResult !== 'undefined';
    },
    newStatus() {
      return this.$store.state.cmsApplicationControl.status;
    },
    blockMail() {
      return this.mailInfo.has_invalid_email_address;
    },
    onGoing() {
      if (this.base.result_announcement_datetime === null) {
        return true;
      }
      const now = moment();
      const end = moment(this.base.result_announcement_datetime);
      return now.isBefore(end);
    },
    isDeletedUser() {
      return Object.keys(this.mailInfo).length === 0;
    },
    adjusts() {
      return this.$store.state.cmsApplicationControl.adjusts;
    },
    isAfterResult() {
      return this.status === 10 || this.status === 11 || this.status === 14 || this.status === 15 || this.status === 16 || this.status === 12;
    },
    upFileKeys() {
      return this.$store.state.gasApplicationInput.upFileKeys;
    },
    tempUpFileList() {
      return this.$store.state.gasApplicationInput.tempUpFileList;
    },
  },
  methods: {
    async fetchApptype(appId) {
      const param = {
        appId,
      };
      const response = await api.send('/api/cms/applications/edit', param)
        .catch((err) => {
          console.error(err);
        });

      if (!response) {
        await this.alert('申請のデータを取得できませんでした。');
        this.$router.push({ path: 'cms/applications/list' });
        return;
      }
      const { base } = response.data;
      this.base = base;
      this.year = base.year;
      this.name = base.name;
      this.status = base.status;
      this.applicationTypeId = base.application_type_id;
      this.close_datetime = base.close_datetime;
      this.assets = response.data.assets;
      this.authAlternate = response.data.authAlternate;
      this.$store.commit('cmsApplicationControl/updateStatus', base.status);
      this.$store.commit('cmsApplicationControl/updateReason', '');
      this.$store.commit('cmsApplicationControl/updateComments', base.comments);
      this.$store.commit('cmsApplicationControl/updateIsNoneSendMailStatus', false);
      // 評価の事務局調整
      this.$store.commit('cmsApplicationControl/setDbEvalAdjusts', response.data.evalAdjusts);
      this.$store.commit('cmsApplicationControl/updateChangeHistoryList', response.data.changeHistoryList);
      const {
        pages,
        answers,
        applicationId,
        userId,
      } = response.data;
      // ユーザーが削除されていたらからサーバーからnullが来るので空オブジェクトに置き換え
      this.mailInfo = response.data.mailInfo || {};
      this.$store.commit('gasApplicationForm/setDbData', pages);
      this.$store.commit('gasApplicationForm/setUserId', userId);
      this.$store.commit('gasApplicationForm/setApplicationId', Number(applicationId));
      this.$store.commit('gasApplicationForm/setApplicationTypeId', base.application_type_id);
      this.$store.commit('gasApplicationForm/setYear', base.year);
      this.$store.commit('gasApplicationInput/setDbData', { pages, answers });
    },
    async save() {
      const IS_NONE_MAIL_FLG = this.$store.state.cmsApplicationControl.isNoneSendMailStatus;
      let apiUrl = '/api/cms/applications/save';
      let msg = '上記の内容で申請を編集登録します。';
      if (Number(this.newStatus) === CONST_STATUS.APP_STATUS.REJECTED) {
        apiUrl = '/api/cms/applications/reject';
        msg = '申請を棄却します。\n申請データは削除されます。';
      }
      if (this.status !== Number(this.newStatus) && IS_NONE_MAIL_FLG) {
        msg += '\nステータスが変更されているため、申請者に通知の登録が行われます。';
      } else if (this.status !== Number(this.newStatus) && !this.blockMail && !this.isDeletedUser) {
        msg += '\nステータスが変更されているため、申請者にメールの送信と通知の登録が行われます。';
      } else if (this.status !== Number(this.newStatus) && !this.isDeletedUser) {
        msg += '\nステータスが変更されてますが、申請者へのメール送信がブロックされているため、通知の登録のみが行われます。';
      }
      if (Number(this.newStatus) === CONST_STATUS.APP_STATUS.REJECTED) {
        msg += '\n申請を棄却してもよろしいですか？';
      } else {
        msg += '\n登録してもよろしいですか？';
      }
      if (!await this.confirm(msg)) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const storeData = {
        applicationId: this.applicationId,
        apptypeId: this.applicationTypeId,
        userId: this.$store.state.gasApplicationForm.user_id,
        inputs: JSON.stringify(this.$store.state.gasApplicationInput.inputs),
        control: JSON.stringify(this.$store.state.cmsApplicationControl),
      };
      const { files } = this.$store.state.gasApplicationInput;
      Object.keys(files).forEach((key) => {
        storeData[key] = files[key];
      });
      const response = await api.sendForm(apiUrl, storeData)
        .catch(async (err) => {
          if (err.response.status === 422) {
            await this.alert('入力内容に誤りがありました。修正してください。', false);
            this.$store.commit('gasApplicationInput/setErrMessages', err.response.data.formErrs);
            this.pageErrs = err.response.data.pageErrs;
          } else {
            await this.alert(err.response.data.msg, false);
          }
          return false;
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      if (Number(this.newStatus) === CONST_STATUS.APP_STATUS.REJECTED) {
        await this.alert('申請を棄却しました。');
        this.$router.push({ path: '/cms/applications/list' });
        return;
      }
      await this.alert('データを更新しました。');
      this.$store.dispatch('page/onLoading');
      this.$store.commit('gasApplicationForm/init');
      this.$store.commit('gasApplicationForm/setIsCms', true);
      this.hasPageErr = []; // タブのエラーマーク初期化
      const { appId } = this.$route.params;
      this.applicationId = appId;
      await this.fetchApptype(appId);
      this.$store.dispatch('page/offLoading');
    },
    async downloadOpenFile(assetId, assetName) {
      const param = {
        assetId,
      };
      this.$store.dispatch('page/onWaiting');
      const requireBlob = true;
      const response = await api.send('/api/cms/applications/download/file/form', param, requireBlob)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, assetName);
    },
    async downloadFormFile(fieldId, optionId, fileName) {
      const param = {
        application_id: this.$store.state.gasApplicationForm.application_id,
        field_id: fieldId,
        option_id: optionId,
        answer_no: this.$store.state.gasApplicationInput.answer_no, // ファイルは当面１つ固定
      };
      param.user_id = this.$store.state.gasApplicationForm.user_id;
      const url = '/api/cms/applications/download/uploadedFile';

      this.$store.dispatch('page/onWaiting');
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      this.$store.commit('gasApplicationInput/setAnsNum', 1);
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
    async nextApp() {
      const newIndex = this.filterdIdListIndex + 1;
      this.$router.push({ path: `/cms/applications/edit/${this.filterdIdList[newIndex]}` });
      this.$store.dispatch('page/onLoading');
      this.applicationId = this.filterdIdList[newIndex];
      await this.fetchApptype(this.filterdIdList[newIndex]);
      this.$store.dispatch('page/offLoading');
    },
    async prevApp() {
      const newIndex = this.filterdIdListIndex - 1;
      this.$router.push({ path: `/cms/applications/edit/${this.filterdIdList[newIndex]}` });
      this.$store.dispatch('page/onLoading');
      this.applicationId = this.filterdIdList[newIndex];
      await this.fetchApptype(this.filterdIdList[newIndex]);
      this.$store.dispatch('page/offLoading');
    },
    setErrors(userErrs) {
      // ユーザー
      this.hasUserErr = Object.keys(userErrs).length > 0;
      // フォーム
      const { inputs } = this.$store.state.gasApplicationInput;
      Object.keys(this.pages).forEach((index) => {
        const { fields } = this.pages[index];
        const thisPageFieldIdList = Object.keys(fields).map((fieldKey) => {
          return fields[fieldKey].field_id;
        });
        let findFormErr = inputs.find((input) => {
          const searchedIndex = thisPageFieldIdList.indexOf(input.field_id);
          const hasErr = input.errMessages.length > 0;
          return searchedIndex !== -1 && hasErr;
        });
        // 例外的にページにエラーマークをつけたいとき
        if (this.pageErrs.includes(Number(index))) {
          findFormErr = true;
        }
        this.hasPageErr.splice(index, 1, findFormErr);
      });
    },
    async downloadApploadedFile(uploadedFileKey, index, fileName) {
      const fileKey = this.upFileKeys[uploadedFileKey][index];
      const url = '/api/cms/confupfile/fileDownload';
      this.$store.dispatch('page/onWaiting');
      if (!this.tempUpFileList[uploadedFileKey] || !this.tempUpFileList[uploadedFileKey][fileKey]) {
        const itemNumber = `${uploadedFileKey}-${fileKey}`;
        const tempUpUrl = '/api/cms/confupfile';
        const tempParam = {
          itemNumber: JSON.stringify(itemNumber),
        };
        const { files } = this.$store.state.gasApplicationInput;
        tempParam.file = files[uploadedFileKey];
        const tempResponse = await api.sendForm(tempUpUrl, tempParam, true)
          .catch((err) => {
            console.error(err);
          });
        if (!tempResponse) {
          this.$store.dispatch('page/offWaiting');
          await this.alert('ファイルのダウンロードに失敗しました。再度アップロードしてお試しください。');
          return;
        }
        const saveTempResponse = {
          target: uploadedFileKey,
          subKey: fileKey,
          path: tempResponse.data.path,
        };
        this.$store.commit('gasApplicationInput/saveTempPath', saveTempResponse);
      }
      const path = this.tempUpFileList[uploadedFileKey][fileKey];
      const param = {
        fileName: path,
      };
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    this.$store.commit('gasApplicationForm/init');
    this.$store.commit('gasApplicationForm/setIsCms', true);
    const { appId } = this.$route.params;
    this.applicationId = appId;
    await this.fetchApptype(appId);
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style scoped>
  .bottonArea {
    width: 1100px;
  }
  .errorMark {
    position: absolute;
    top: -7px;
    left: 150px;
  }
  .warning {
    color: #dc3545;
  }
  .subject {
    border-bottom: .1rem solid #A0A9D0;
    margin: 3rem auto 0;
    width: 1080px;
  }

  .err-wrap>p{
    color: #dc3545;
  }
</style>
