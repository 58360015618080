<template>
  <perfect-scrollbar v-if="!isUnderShow" class="px-4 pt-4 pb-2 control-wrap">

    <div class="flex flex-between">
      <span class="bold">ステータス管理</span>
      <span>
        <span class="bold mr-5" v-if="userName">
          申請者氏名： {{userName[0].field_answer_text}}
          <span v-if="userName[1]"> {{userName[1].field_answer_text}}</span>
          </span>
        <span class="bold">申請番号： {{base.code}}</span>
      </span>
    </div>
    <hr class="mt-1 cotrol-line">

    <b-row class="mt-2">
      <b-col sm="3" class="label">
        <label class="labelText bold">申請日時</label>
      </b-col>
      <b-col sm="9" class="flex flexMiddle">
        {{base.last_send_date}}
      </b-col>
    </b-row>

    <hr>

    <b-row
      v-if="base.last_acceptance_date"
      class="mt-2">
      <b-col sm="3" class="label">
        <label class="labelText bold">受領日時</label>
      </b-col>
      <b-col sm="9" class="flex flexMiddle">
        {{base.last_acceptance_date}}
      </b-col>
    </b-row>

    <hr v-if="base.last_acceptance_date">

    <b-row class="mt-2">
      <b-col sm="3" class="label">
        <label class="labelText bold">申請者メールアドレス</label>
      </b-col>
      <b-col sm="9" class="flex flexMiddle">
        {{mailInfo.login_id}}
        <span v-if="isDeletedUser" class="error">
          ユーザーが削除されているため、メール送信はできません。
        </span>
      </b-col>
      <b-col sm="3" v-if="mailInfo.has_invalid_email_address"></b-col>
      <b-col sm="9" v-if="mailInfo.has_invalid_email_address"
        class="flex flexMiddle error">
        システムからこのユーザーへのメールが届かなかったため、このユーザーへのメール送信はブロックされています。<br>
        ユーザーがログインに成功すると、ブロックが解除されます。
      </b-col>
    </b-row>
    <hr>

    <b-row class="mt-2" v-if="isChangeHistory">
      <b-col sm="3" class="label">
        <label class="labelText bold">変更申請</label>
      </b-col>
      <b-col v-if="changeHistoryList.length == 0" sm="9" class="flex flexMiddle">
        なし
      </b-col>
      <b-col v-else sm="9" class="flexMiddle">
        <div v-for="(changeHistoryData, history_no) in changeHistoryList" :key="`history_${history_no}`">
          <b-link :to="'/cms/applications/change/edit/'+changeHistoryData.id">
            第{{history_no + 1}}回計画変更申請
          </b-link>
          <hr v-if="history_no !== (changeHistoryList.length-1)">
        </div>
      </b-col>

    </b-row>
    <hr v-if="isChangeHistory">

    <b-row class="mt-2">
      <b-col sm="3" class="label">
        <label class="labelText bold">現在のステータス</label>
      </b-col>
      <b-col sm="9" class="flex flexMiddle">
        {{statusTexts[status]}}
      </b-col>
    </b-row>

    <hr>

    <b-row class="">
      <b-col sm="3" class="label">
        <label class="labelText bold">ステータス</label>
      </b-col>
      <b-col sm="4">
        <b-form-select
          class="h-50px"
          @input="updateStatus"
          :value="newStatus"
          :options="!isAfterResult ? newStatusList : afterStatusList"
          :disabled="(!onGoing && !isAfterResult)">
          <template v-slot:first>
            <option :value="null">-- 選択してください。 --</option>
          </template>
        </b-form-select>
        <b-form-checkbox
          class=""
          name="isNoneSendMail"
          :value="true"
          @change="updateIsNoneSendMailStatus($event)"
          :disabled="(!onGoing && !isAfterResult) || newStatus == status"
          :data-text="isNoneSendMailStatus"
          :checked="isNoneSendMailStatus">
          <div class="choice-text inlineBlock">ステータス変更メールを送らない</div>
        </b-form-checkbox>
      </b-col>
    </b-row>

    <hr>

    <b-row class="mb-3">
      <b-col sm="3" class="label">
        <label
          :class="[
            'labelText',
            'bold',
            !canInputReason || newStatus == status ? 'opacity03' : '',
          ]">
          受領・差戻・棄却理由</label>
      </b-col>
      <b-col sm="9">
        <div v-if="newStatus != status && canInputReason && (onGoing || isAfterResult)">
          <span>最新：</span>
          <b-form-textarea
            @input="updateReason"
            :value="reason"
            :disabled="!canInputReason || (!onGoing && !isAfterResult)"
            :rows="4"
            />
          <hr v-if="comments.length">
        </div>
        <div v-for="(comment, comment_no) in comments" :key="`comment_${comment_no}`">
          <span>{{comments.length - comment_no}}回目：登録日 {{comment.reg_date}}</span>
          <b-form-textarea
            disabled
            :value="comment.comment"
            :rows="4"
            />
          <hr v-if="comment_no !== (comments.length-1)">
        </div>
      </b-col>
    </b-row>
    <hr
      v-if="Object.keys(adjusts).length > 0">

    <b-row
      v-if="Object.keys(adjusts).length > 0"
      class="mb-3">
      <b-col sm="3" class="label">
        <label
          :class="[
            'labelText',
            'bold',
            constStatus.RECEIPT !== Number(newStatus) || !onGoing ? 'opacity03' : '',
          ]">
          事務局調整</label>
      </b-col>
      <b-col sm="9">
        <b-input-group
          class="mb-2"
          v-for="(adjust, itemId) in adjusts" :key="`adjust_${itemId}`">
          <b-input-group-prepend
           :class="[
              'mr-2 flex flexMiddle adjust-input-label',
              constStatus.RECEIPT !== Number(newStatus) || !onGoing ? 'opacity03' : '',
            ]">
            {{adjust.label}}
          </b-input-group-prepend>
          <div class="adjust-input">
            <b-form-select
              class="h-50px"
              :options="adjustOption(adjust.param)"
              :value="adjust.score"
              @change="updateAdjust(itemId, $event)"
              :disabled="constStatus.RECEIPT !== Number(newStatus) || !onGoing"
            >
              <template v-slot:first>
                <option :value="null">---</option>
              </template>
            </b-form-select>
          </div>
        </b-input-group>
      </b-col>
    </b-row>

    <div>
    </div>

  </perfect-scrollbar>
  <perfect-scrollbar v-else class="px-4 pt-4 pb-2 control-wrap">
    <b-row class="mt-2">
      <b-col sm="3" class="label">
        <label class="labelText bold">現在のステータス</label>
      </b-col>
      <b-col sm="9" class="flex flexMiddle">
        {{statusTexts[status]}}
      </b-col>
    </b-row>

    <hr>

    <b-row class="">
      <b-col sm="3" class="label">
        <label class="labelText bold">ステータス</label>
      </b-col>
      <b-col sm="4">
        <b-form-select
          class="h-50px"
          @input="updateStatus"
          :value="newStatus"
          :options="!isAfterResult ? newStatusList : afterStatusList"
          :disabled="(!onGoing && !isAfterResult)">
          <template v-slot:first>
            <option :value="null">-- 選択してください。 --</option>
          </template>
        </b-form-select>
        <b-form-checkbox
          class=""
          name="isNoneSendMail"
          :value="true"
          @change="updateIsNoneSendMailStatus($event)"
          :disabled="(!onGoing && !isAfterResult) || newStatus == status"
          :data-text="isNoneSendMailStatus"
          :checked="isNoneSendMailStatus">
          <div class="choice-text inlineBlock">ステータス変更メールを送らない</div>
        </b-form-checkbox>
      </b-col>
    </b-row>

    <hr>

    <b-row class="mb-3">
      <b-col sm="3" class="label">
        <label
          :class="[
            'labelText',
            'bold',
            !canInputReason || newStatus == status ? 'opacity03' : '',
          ]">
          受領・差戻・棄却理由</label>
      </b-col>
      <b-col sm="9">
        <div v-if="newStatus != status && canInputReason && (onGoing || isAfterResult)">
          <span>最新：</span>
          <b-form-textarea
            @input="updateReason"
            :value="reason"
            :disabled="!canInputReason || (!onGoing && !isAfterResult)"
            :rows="4"
            />
          <hr v-if="comments.length">
        </div>
        <div v-for="(comment, comment_no) in comments" :key="`comment_${comment_no}`">
          <span>{{comments.length - comment_no}}回目：登録日 {{comment.reg_date}}</span>
          <b-form-textarea
            disabled
            :value="comment.comment"
            :rows="4"
            />
          <hr v-if="comment_no !== (comments.length-1)">
        </div>
      </b-col>
    </b-row>
    <hr
      v-if="Object.keys(adjusts).length > 0">

    <b-row
      v-if="Object.keys(adjusts).length > 0"
      class="mb-3">
      <b-col sm="3" class="label">
        <label
          :class="[
            'labelText',
            'bold',
            constStatus.RECEIPT !== Number(newStatus) || !onGoing ? 'opacity03' : '',
          ]">
          事務局調整</label>
      </b-col>
      <b-col sm="9">
        <b-input-group
          class="mb-2"
          v-for="(adjust, itemId) in adjusts" :key="`adjust_${itemId}`">
          <b-input-group-prepend
           :class="[
              'mr-2 flex flexMiddle adjust-input-label',
              constStatus.RECEIPT !== Number(newStatus) || !onGoing ? 'opacity03' : '',
            ]">
            {{adjust.label}}
          </b-input-group-prepend>
          <div class="adjust-input">
            <b-form-select
              class="h-50px"
              :options="adjustOption(adjust.param)"
              :value="adjust.score"
              @change="updateAdjust(itemId, $event)"
              :disabled="constStatus.RECEIPT !== Number(newStatus) || !onGoing"
            >
              <template v-slot:first>
                <option :value="null">---</option>
              </template>
            </b-form-select>
          </div>
        </b-input-group>
      </b-col>
    </b-row>

    <div>
    </div>

  </perfect-scrollbar>
</template>

<script>
import CONST_STATUS from '@/constants/appStatus';


export default {
  name: 'cmsApplicationControl',
  components: {
  },
  props: {
    base: {},
    status: { type: Number, default: null },
    mailInfo: { type: Object, default: () => {} },
    onGoing: { type: Boolean },
    userName: {},
    isUnderShow: { type: Boolean, default: false },
    isAuthAlternate: { type: Boolean, default: false },
    isChangeHistory: { type: Boolean, default: false },
  },
  data() {
    return {
      constStatus: CONST_STATUS.APP_STATUS,
      statusTexts: CONST_STATUS.APP_STATUS_TEXTS,
    };
  },
  methods: {
    updateStatus(value) {
      this.$store.commit('cmsApplicationControl/updateStatus', value);
      if (parseInt(value, 10) === this.status) {
        this.$store.commit('cmsApplicationControl/updateIsNoneSendMailStatus', false);
      }
    },
    updateReason(value) {
      this.$store.commit('cmsApplicationControl/updateReason', value);
    },
    updateAdjust(itemId, value) {
      const param = { itemId, value };
      this.$store.commit('cmsApplicationControl/updateAdjust', param);
    },
    updateIsNoneSendMailStatus(value) {
      this.$store.commit('cmsApplicationControl/updateIsNoneSendMailStatus', value);
    },
  },
  computed: {
    afterStatusList() {
      const option = CONST_STATUS.CMS_APP_AFTER_STATUS;
      const sortNum = CONST_STATUS.CMS_APP_AFTER_SORT_STATUS;
      const showOption = [];
      const authAlternateFlg = this.isAuthAlternate;
      Object.keys(option).forEach((key) => {
        if (!authAlternateFlg && key === '14') {
          return;
        }
        showOption.push({ id: sortNum[key], value: key, text: option[key] });
      });
      showOption.sort((a, b) => a.id - b.id);
      return showOption;
    },
    newStatusList() {
      const option = CONST_STATUS.CMS_APP_STATUS_OPTION;
      const sortNum = CONST_STATUS.CMS_APP_STATUS_SORT_LIST;
      const showOption = [];
      const authAlternateFlg = this.isAuthAlternate;
      Object.keys(option).forEach((key) => {
        if (!authAlternateFlg && key === '14') {
          return;
        }
        showOption.push({ id: sortNum[key], value: key, text: option[key] });
      });
      showOption.sort((a, b) => a.id - b.id);
      return showOption;
    },
    canInputReason() {
      const hasReasonStatusList = CONST_STATUS.HAS_APP_REASON_STATUS;
      return hasReasonStatusList.indexOf(Number(this.newStatus)) !== -1;
    },
    newStatus() {
      return this.$store.state.cmsApplicationControl.status;
    },
    reason() {
      return this.$store.state.cmsApplicationControl.reason;
    },
    comments() {
      return this.$store.state.cmsApplicationControl.comments;
    },
    changeHistoryList() {
      return this.$store.state.cmsApplicationControl.changeHistoryList;
    },
    adjusts() {
      return this.$store.state.cmsApplicationControl.adjusts;
    },
    isNoneSendMailStatus() {
      return this.$store.state.cmsApplicationControl.isNoneSendMailStatus;
    },
    isDeletedUser() {
      return Object.keys(this.mailInfo).length === 0;
    },
    adjustOption() {
      return (param) => {
        // eslint-disable-next-line
        const scoreOption = [...Array(param).keys()].map(i => i += 1);
        return scoreOption;
      };
    },
    isAfterResult() {
      // return this.status === 10 || this.status === 11 || this.status === 14 || this.status === 15 || this.status === 16;
      return this.status === 10 || this.status === 11 || ((this.status === 12 || this.status === 14 || this.status === 15 || this.status === 16) && !this.onGoing);
    },
  },
  // ロード画面
  created() {
  },
};
</script>

<style scoped>
  .adjust-input-label {
    min-width: 200px;
  }
  .adjust-input {
    width: 200px;
  }
  .control-wrap {
    background-color: #DDD;
  }

  .cotrol-line {
    height: 2px;
    background-color: #BBB;
  }

  .opacity03 {
    opacity: 0.3;
  }

  .h-50px {
    height: 50px;
  }

  .label {
    height: 50px;
  }

  .labelText {
    position: absolute;
    top: 10px;
  }

  .error {
    color: #dc3545;
  }
</style>
